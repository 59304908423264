@import "../../../scss/settings/colors";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 48px !important;
  width: 24px !important;
}
.image-gallery-thumbnail {
  width: auto;
}
.image-gallery-thumbnail-image {
  object-fit: scale-down;
  height: 80px !important;
  @media screen and (max-width: 768px) {
    height: 50px !important;
  }
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  padding: 0px 20px;
  height: auto;
}

.post-media-document-grid {
  padding: 1rem;
  display: grid;
  grid-template-columns: 100%;
  background-color: #e6ebf24d;
  border-top: 2px solid #e6ebf2;
  border-bottom: 2px solid #e6ebf2;
  @media screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
  }
}
.post-media-document-grid-item {
  display: flex;
  align-items: center;
  padding-inline: 1rem;
}
.post-media-document-view-more {
  padding: 1rem;
  display: grid;
  place-content: end;
  background-color: #e6ebf24d;
  border-bottom: 2px solid #e6ebf2;
}

.document-list-modal {
  .ant-modal-body {
    padding: 0;
  }
  .document-list-modal-body {
    max-height: 300px;
    overflow-y: auto;
    .document-list-document-wrapper {
      margin-inline: 1rem;
      &:last-child {
        .nb-divider {
          display: none;
        }
      }
    }
  }
}

.post-media-grid {
  display: grid;
  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  &.post-media-grid-1 {
    grid-template-columns: 100%;
    background: #cccccc;
    img {
      max-height: 500px;
      object-fit: contain;
      width: 100%;
    }
  }
  &.post-media-grid-2 {
    grid-template-columns: 50% 50%;
    grid-template-rows: 300px;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
      &:nth-child(1) {
        border-right: 2px solid #ffffff;
      }
      &:nth-child(2) {
        border-left: 2px solid #ffffff;
      }
      &:first-child {
        grid-column: 1 / span 1;
      }
      &:last-child {
        grid-column: 2 / span 1;
      }
    }
  }

  &.post-media-grid-3 {
    grid-template-columns: 50% 50%;
    grid-template-rows: 250px 250px;
    img {
      max-width: 100%;
      object-fit: cover;
      width: 100%;

      &:first-child {
        height: 100%;
        grid-column: 1 / span 1;
        grid-row: 1 / span 2;

        border-right: 2px solid #ffffff;
      }
      &:nth-child(2) {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
        height: 100%;

        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
      &:last-child {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        height: 100%;

        border-left: 2px solid #ffffff;
        border-top: 2px solid #ffffff;
      }
    }
  }
  &.post-media-grid-4 {
    grid-template-columns: 50% 50%;
    grid-template-rows: 250px 250px;
    img {
      max-width: 100%;
      object-fit: cover;
      height: 100%;
      width: 100%;

      &:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;

        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
      &:nth-child(2) {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;

        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
      &:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;

        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
      }
      &:nth-child(4) {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;

        border-top: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
      }
    }
  }
  &.post-media-grid-5 {
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(6, 83.3333px);
    img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;

      &:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 3;

        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
      &:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 4 / span 3;

        border-right: 2px solid #ffffff;
        border-top: 2px solid #ffffff;
      }
      &:nth-child(3) {
        grid-column: 2 / span 1;
        grid-row: 1 / span 2;

        border-bottom: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
      }
      &:nth-child(4) {
        grid-column: 2 / span 1;
        grid-row: 3 / span 2;

        border-top: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
      &:nth-child(5) {
        grid-column: 2 / span 1;
        grid-row: 5 / span 2;

        border-top: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
      }
    }
    .post-media-content-image-span {
      grid-column: 2 / span 1;
      grid-row: 5 / span 2;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      &::before {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .post-media-content-image-more-count {
        position: absolute;
        font-size: 2rem;
        color: #ffffff;
      }
    }
  }
}
