@import "./../../../../scss/settings/variable";

.mobile-sheet-container {
  [data-rsbs-header] {
    svg.btn-icon {
      width: 20px;
      height: 20px;
      * {
        stroke: white;
      }
    }
    padding: 0px;
    background-color: $grey3;
    .title {
      color: white;
      font-weight: bold;
      font-size: 1rem;
    }
    &::before {
      display: none;
    }
  }
  .ant-btn:hover {
    svg {
      * {
        stroke: inherit;
      }
    }
  }
}
