.neighbours-list-container {
  width: 100%;
  padding: 0.75rem 0;
  list-style-type: none;
  margin: 0;

  .neighbours-list-item {
    padding: 1rem 0;
    margin: 0;
    @extend .apply-bottom-border;

    @include xs {
      .ant-radio-wrapper {
        padding-left: 1.35rem !important;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &::marker {
      content: "";
    }

    .neighbours-list-item-text {
      font-size: 1.15rem;
      margin-left: 1rem;
      white-space: initial;

      @include xs {
        font-size: 1rem;
        margin-left: 0.25rem;
      }
    }
  }
  .communities-list-item {
    display: flex;
    padding: 1rem 0;
    margin: 0;

    @include xs {
      .ant-radio-wrapper {
        padding-left: 0.35rem !important;
      }
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &::marker {
      content: "";
    }

    .communities-list-item-text {
      font-size: 1.15rem;
      margin-left: 1rem;
      white-space: initial;

      @include xs {
        font-size: 1rem;
        margin-left: 0.25rem;
      }
    }
  }
}

.collapse-container {
  .collapse-header-container {
    .ant-collapse-header {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      @extend .apply-bottom-border;

      .ant-collapse-arrow {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0;
        padding: 0;
        top: 0;
        margin-right: 1rem;
      }
    }

    .ant-collapse-content-box {
      .ant-checkbox-group {
        width: 100%;
      }
    }

    .neighbouthood-list {
      height: 243px;
    }
  }

  .collapse-header-no-border {
    .ant-collapse-header {
      border-bottom: none;
    }
  }
}
