@import "../../../../scss/tools/mixins";

.info-container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 0.5em;
  margin: 15px 2px;
  font-size: 0.9em;
  align-items: center;
  color: $black-color;
  img {
    margin-right: 5px;
    height: 0.9em;
  }
}
