@import "../../../../scss/settings/_colors.scss";

.mentions {
  width: 100%;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__highlighter {
  padding: 10px;
  line-height: initial;
}
.mentions--multiLine .mentions__input {
  border: 1px solid transparent;
  padding: 10px;
  outline: 0;
  margin: 0px !important;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: $primary-color;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: $primary-color;
  text-shadow:
    1px 1px 1px rgb(242, 242, 242),
    1px -1px 1px rgb(242, 242, 242),
    -1px 1px 1px rgb(242, 242, 242),
    -1px -1px 1px rgb(242, 242, 242);
  font-weight: bold !important;
  pointer-events: none;
}
