.mobile-menu-container {
  .ant-btn {
    width: unset;
    height: unset;
  }
  .ant-btn:hover {
    svg {
      * {
        stroke: inherit;
      }
    }
  }
}
