.nb-edit-modal {
    max-width: 620px;
    width: 100%!important;
}

.nb-edit-modal-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.nb-edit-modal-icon {
    margin-top: -80px;
    background: white;
    padding: 5px;
    border-radius: 50%;
    min-width: 110px;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-modal-body {
    width: 100%!important; 
    .ant-checkbox-group {
        width: 100%;
    }
}
.nb-modal-content {
    overflow: initial!important;
    display: flex;
    justify-content: center;
    .ant-form-item {
        .ant-form-item-label {
            width: 100%;
            text-align:start!important;
            .form-label-item {
                display: flex;
                font-size: 16px;
                svg {
                    margin-right: 10px;
                }
            }
        }
    }
}