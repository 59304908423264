@import "../../../../scss/tools/mixins.scss";
@import "../../../../scss/settings/_colors.scss";

.ant-affix {
  box-shadow: 0px 3px 10px $grey !important;
}
.ant-layout-header.app-header {
  border: 1px solid #e7ebf5;

  @include sm-max-below {
    display: none;
  }
  background: white;
  padding: 0.5rem;
  height: 77px;

  .ant-row {
    background: #fff;
    padding: 0px 0.4rem;
  }
  .app-header-left {
    display: flex;
    .app-header-left-logo {
      max-width: 200px;
      min-width: 140px;
      margin-right: 1rem;
      img {
        width: 100%;
        height: 26px;
      }
    }
    .app-header-left-search {
      max-width: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      > span {
        min-height: 44px;
        border-radius: 5px;
      }
      .search-input-suffix {
        line-height: 0;
        background: #bac8ca;
        display: flex;
        height: 100%;
        min-width: 30px;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        svg {
          height: 11px;
          width: 11px;
          g,
          line {
            stroke: #ffffff;
          }
        }
      }
    }
  }
  .app-header-right {
    display: flex;
    justify-content: flex-end;
    .ant-menu-horizontal {
      border-bottom: 0;
    }
    .ant-menu-horizontal > .ant-menu-item::after {
      display: none !important;
    }
    .app-header-menu {
      line-height: 1rem;
      display: flex;
      align-items: center;
      min-width: 100%;
      justify-content: flex-end;
      padding: 10px 20px;
      .app-header-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 80px;
        border-bottom: none;
        padding-inline: 0.4rem;
        cursor: pointer;
        &.item-1 {
          svg {
            height: 18px;
            width: 18px;
          }
        }
        &.item-2 {
          svg {
            height: 18px;
            width: 18px;
          }
        }
        &:nth-last-child(2) {
          margin-right: 0;
          text-align: center;
          svg {
            height: 18px;
            width: 18px;
          }
        }
        &:last-child {
          margin-right: -1rem;
          > span {
            text-align: center;
          }
        }
        &.user-menu-item {
          &:hover {
            color: inherit;
          }
          &.ant-menu-item-selected {
            color: inherit;
          }
          flex-direction: row;
          > span {
            margin-right: 0.8rem;
          }
        }
        .app-header-menu-item-icon {
          margin-right: 0;
        }
      }
      .app-header-user-edit-profile {
        flex-direction: row !important;

        border-radius: 50px;
        top: 0 !important;
        .header-profile-image-text {
          color: $black !important;
        }
      }
    }

    .header-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .app-header-user {
      display: flex;
      align-items: center;
    }
  }
}
.app-header-popover {
  @include sm-max-below {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-inner {
    padding: 0px !important;
    border-radius: 3px !important;
  }
}
.pop-menu-content {
  .pop-menu-content-item {
    display: flex;
    min-width: 250px;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    padding: 12px 16px;
    line-height: 0;
    min-height: 46px;
    border-radius: 0px !important;
    justify-content: flex-start;
    &:last-child {
      border-bottom: none;
    }
    .pop-menu-content-item-label {
      flex: 1;
      margin: 0 0.5rem;
    }
  }
}

.ant-layout-header.app-header-mobile {
  height: 80px;
  padding: 0;
  line-height: inherit;
  background: #ffffff;
  display: none;
  align-items: stretch;
  .notification-dropdown {
    .notification-icon {
      height: 16px;
      width: 25px;
    }
  }

  @include sm-max-below {
    display: flex;
  }
  @include xs {
    height: 50px;
  }
  .ant-menu.app-header-mobile-menu {
    flex: 1;
    border-bottom: none;
    height: 100%;
    line-height: 1rem;
    .ant-menu-submenu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .app-header-mobile-menu-item {
      // flex: 1;
      margin: 0;
      // width: 16.666%;
      width: 14%;
      height: 100%;
      margin-top: -1px;
      > div {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
      }

      span {
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-menu-horizontal::after {
      display: none;
    }
    .ant-menu-item-active,
    .ant-menu-item-selected {
      &::after {
        border: transparent !important;
      }
    }
  }
}

.app-header-mobile-menu-item-drawer {
  display: none;
}
.pop-menu-user-account-setting {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  span {
    max-width: 1.6rem;
    max-height: 1.6rem;
  }
}

.header-sidemenu-icon-close {
  background: transparent;
  border: 0;
  width: fit-content;
  text-align: right;
  display: block !important;
  z-index: 11;
  align-self: flex-end;
}

.drawer-user-details {
  margin-bottom: 0.6rem;
  position: relative;
  top: -10px;
  li {
    display: flex;
    align-items: center;
  }
}

.drawer-setting-privacy {
  .pop-menu-content .pop-menu-content-item {
    min-height: 36px;
    border-bottom: 0;
    min-width: auto;
  }
  .pop-menu-user-account-setting {
    display: none;
  }
  .setting-popover-user-account {
    display: none;
  }
}
@include sm-max-below {
  .app-header-mobile-menu-item {
    .app-header-mobile-menu-item-heading {
      display: none !important;
    }
  }
  .app-header-mobile-menu-item-drawer {
    &.ant-drawer.ant-drawer-right,
    &.ant-drawer-open {
      display: block;

      .ant-drawer-body {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .ant-drawer .ant-drawer-content,
  .landing-header-right-content {
    background-color: $body-bg-color;
  }

  .pop-menu-content {
    .pop-menu-content-item-icon-1 {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}
@include sm-max-below {
  .app-header-mobile {
    .ant-layout-header {
      display: flex;
      height: 50px;
    }
    .app-header-mobile-logo,
    .app-header-mobile-hamburger {
      max-width: 3.5rem;
    }
  }
}

.search-section {
  overflow-y: scroll;
  max-height: 600px;
  box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 29px 0px;
  div {
    padding: 3px 0px;
  }
  .show-more-btn {
    button {
      width: 100%;
      justify-content: center;
      display: flex;
      height: 20px;
    }
  }
}
.search-content-text {
  p,
  a {
    color: black !important;
  }
}
.profiles-menu-avatar {
  .anticon-user {
    margin-right: 0 !important;
  }
}

.ant-menu-vertical {
  .mobile-search-bar {
    display: none;
  }
}
.show-more-btn {
  color: $black;
}
.mobile-search-bar {
  display: none;
  transition: 0.3s ease-in;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  .anticon-search {
    opacity: 0;
  }
  .iconCross {
    position: absolute;
    right: 18px;
    z-index: 11;
    top: 32px;
    width: 8px;
    @include xs {
      width: 7px;
      top: 17px;
    }
  }
  .ant-input-affix-wrapper {
    min-height: 80px;
    border-radius: 0 !important;
    @include xs {
      min-height: 32px;
    }
  }
  &.mobile-search-bar-block {
    @include md-max-below {
      display: block;
    }
  }

  &.mobile-search-bar-inline {
    @include md-max-below {
      display: none;
    }
  }
}
.overlay-search-mobile {
  .search-section {
    width: auto;
  }
  &.dropdown-open {
    display: block;
  }
  &.dropdown-close {
    display: none;
  }
}
.notification-dropdown {
  display: flex;
  align-items: center;
  flex-direction: column;
  & .notification-label {
    color: $base-font-color;
    &:hover {
      color: $primary-color;
    }
    @include sm-max-below {
      display: none;
    }
  }
}
.setting-label {
  color: $base-font-color;
  &:hover {
    color: $primary-color;
  }
}
@include sm-max-below {
  .notification-container {
    inset: 70px auto 100px !important;
    &.ant-dropdown-placement-bottomRight {
      width: 100%;
      padding: 12px 10px 0px 10px;
    }
  }
}
.notification-container {
  padding: 0px 10px !important;
  .ant-dropdown-menu {
    height: fit-content;
    &::-webkit-scrollbar {
      width: 10px;
      position: absolute;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px #ccc;
      background: #f7fdfb;
      position: relative;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f7fdfb;
      border-radius: 0;
      background: #f7fdfb;
    }
    .ant-dropdown-menu-title-content {
      width: 100%;
      @include xs {
        .nb-base-text {
          max-width: 230px;
        }
      }
    }
  }
}
.notification-dropdown {
  right: 0px !important;
  .ant-scroll-number {
    right: 0px !important;
  }
  .notification-icon {
    height: 20px;
    width: 30px;
  }
}
.search-dropdown {
  max-width: 70%;
  @include xxs {
    max-width: 340px;
  }
  @include sm {
    max-width: 375px;
  }
  @include md {
    max-width: 575px;
  }
}
.profile-alert {
  width: 100%;
  padding: 15px 24px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow:
    rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px -4px,
    rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
  text-align: center;
  background-color: rgba(255, 200, 71, 0.7);
}
