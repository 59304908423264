@import "../../scss/tools/mixins";
@import "../../scss/settings/_colors.scss";

.stuffBtn {
  background-color: $white-color !important;
  color: #000 !important;
  border-color: #fffcfb !important;
  border-radius: 10px !important;
  min-height: 60px !important;
}
.keepSign {
  display: flex;
  justify-content: space-between;
}
.login {
  padding: 0px 20px;
  @media (min-width: 700px) {
    padding: 0px;
  }
  .login-title {
    font-size: 1.4rem;
    margin-bottom: 0px;
    text-align-last: center;
    @media (min-width: 700px) {
      font-size: 1.8rem;
      text-align-last: flex-start;
    }
  }
  .login-link {
    font-size: 0.8rem;
    text-align-last: center;
    @media (min-width: 700px) {
      font-size: 1rem;
      text-align-last: flex-start;
    }
  }
}
.ant-layout-footer {
  .onboarding-layout-footer-right {
    justify-content: center;
    padding: 15px 0px;
  }
}
.login-form {
  .nb-button {
    height: 30px;
    @media (min-width: 700px) {
      height: 55px;
    }
  }
}
