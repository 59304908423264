@import "./../../scss/settings/colors";

.journey-card-container {
  padding: 0 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey2;
  border-radius: 4px;
}

.journey-tabs-container {
  .ant-tabs-nav {
    margin-bottom: 0;

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
      }
    }
  }

  .journey-tab-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;

    &:first-child {
      border-top-left-radius: 0.25rem;
    }

    &:nth-child(7) {
      border-top-right-radius: 0.25rem;
    }

    .ant-tabs-tab {
      margin: 0;
      padding: 0;
      width: 100%;

      .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }

  .journey-tabs-loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    flex-direction: column;

    .title {
      margin-top: 0.5rem;
      color: $grayishBlack;
    }
  }
}

.journey-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.15rem 0.25rem 0.8rem 0.25rem;
  margin-left: -0.1px;
  margin-right: -0.1px;

  @include xs {
    padding: 1.15rem 1.5rem 0.8rem 1.5rem;
  }

  .tab-icon {
    width: 1.15rem;
    height: 1.15rem;
    margin-bottom: 0.25rem;

    &-lg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }

  .tab-title {
    padding: 0;
    margin: 0;
    font-size: 0.85rem;
  }
}

.journey-tab-active {
  border-bottom: 2px solid;
  z-index: 1;
  position: relative;
}

.nb-upload-input {
  border: 1px lightgrey solid;
  border-radius: 6px;
  padding: 0px;
}

.ant-upload-select {
  // border: 1px $grey2 solid;
  // border-radius: 4px;
  padding: 0px;
  span {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.journey-option-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  cursor: pointer;

  &.nb-select-input {
    justify-content: space-between;
    border: 1px $grey2 solid;
    border-radius: 4px;
  }

  &.nb-attach-files {
    align-self: flex-end;
    max-width: 200px;
    justify-content: flex-end;
    background: $grey2;
  }
  @include xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .journey-option-text {
    margin: 5px;
    padding: 0;
    flex: 2;
    max-width: fit-content;
    font-size: 0.85rem;
    &-filled {
      color: $grayishBlack;
    }

    @include xs {
      padding-left: 0.5rem;
    }
  }

  &-disabled {
    cursor: not-allowed;
  }
}

.journey-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 20px;
  height: 100%;
  margin: 5px;
}

.journey-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* Message Journey */
.message-journey-container {
  width: 80%;
  .ant-select-selection-placeholder {
    display: flex;
  }
  .journey-option-left-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  .journey-option-right-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
  }

  .choose-neighbours-container {
    position: absolute;
    width: 80%;
    height: fit-content;
    z-index: 1;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .container {
    min-height: 32em;

    @include xs {
      min-height: 35em;
    }
  }
}

.journey-upload-container {
  .ant-upload {
    width: 100%;

    .journey-option-text {
      color: $grayishBlack;
    }
  }
}

/* Poll Journey */
.poll-container {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0;

  .poll-list-container {
    width: 100%;
    list-style-type: none;
    padding-left: 1.5rem;
    margin: 0;
    border-radius: 1rem;

    .poll-list-item {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      .poll-list-item-ti {
        flex: 2;

        @include xs {
          max-width: 82%;
        }
      }

      .poll-list-item-ai-container {
        flex: 1;
        max-width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include xs {
          max-width: 18%;
          justify-content: flex-end;
        }

        .poll-list-item-ai {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

/* Event Journey */
.event-journey-dt-cotainer {
  margin-bottom: 6rem;

  .event-journey-dt-col {
    @include xs {
      margin-top: 1.25rem;
    }
    @include sm {
      margin-top: 1.25rem;
    }
  }
}

.event-journey-loc-container {
  margin-bottom: 3.75rem;

  @include xs {
    margin-bottom: 6.75rem;
  }
}

.cover-image-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 30em;

  .cover-image-upload-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;

    .ant-upload {
      width: 100%;
    }

    .cover-image-upload-cover {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 10rem;
      border-radius: 0.5rem;
      border: 2px dashed $white;
      cursor: pointer;
      width: 100%;

      .cover-image-upload-text {
        margin: 0;
        padding: 0;
        color: $primary-color-2;
        font-size: 1.35rem;
      }
    }

    .cover-uploaded-img-container {
      width: 100%;
      max-height: 18rem;
      overflow: hidden;
      flex: 1;

      .cover-uploaded-img {
        width: 100%;
      }
    }

    .remove-image-icon {
      color: $white;
      background-color: red;
      border-radius: 50%;
      padding: 0.25rem;
      font-size: 11px;
      cursor: pointer;
    }
  }

  .cover-image-note {
    padding: 1.25rem 0;
    margin: 0;
    width: 100%;
    font-size: 0.9rem;
  }
}

.cover-post-summary-image-container {
  min-height: 15rem;
  @extend .apply-bottom-border;
}

/* Recommendation Journey */
.choose-recommendation-container {
  padding: 1.25rem 1.5rem;

  .q-text {
    padding: 0;
    margin: 0 0 1rem 0;
    font-size: 0.85rem;
  }

  .recommendation-types-container {
    .recommendation-type {
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      padding: 1rem 1.75rem;

      .ant-radio-wrapper {
        display: flex;
        align-items: center;

        .recommendation-type-heading,
        .recommendation-type-text {
          padding: 0;
          margin: 0;
          margin-left: 1rem;
        }

        .recommendation-type-heading {
          color: $grayishBlack;
          font-weight: bold;
          font-size: 0.95rem;
        }

        .recommendation-type-text {
          font-size: 0.85rem;
          white-space: initial;
        }
      }
    }
  }
}

/* Urgent Alert Journey */
.urgent-alert-container {
  .send-text-email-container {
    padding: 1.25rem 1.75rem;
    @extend .apply-bottom-border;

    .send-text {
      padding: 0;
      margin: 0;
    }

    .ste-checks-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .ste-checkbox {
        padding: 0 !important;

        .ste-check-text {
          margin-left: 1rem;
          font-weight: bold;
          color: $primary-color-2;
        }
      }

      @include xs {
        flex-direction: column;

        .ste-checkbox {
          margin-left: 0;
          margin-bottom: 0.5rem;
        }
      }

      @include sm {
        flex-direction: column;

        .ste-checkbox {
          margin-left: 0;
          margin-bottom: 0.5rem;
        }
      }
    }

    .send-text-q {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }

    .send-receiver-text {
      color: $danger;
      font-size: 1rem;
    }
  }

  .popup-icon {
    font-size: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    height: fit-content;
    margin-top: 0.1rem;
  }
}

.send-text-email-popup-overlay {
  .popup-content-container {
    width: 20rem;
    padding: 1rem 1.35rem;

    .popup-content-text {
      color: $grayishBlack;
      font-size: 0.85rem;
    }

    .popup-content-btn-container {
      display: flex;
      justify-content: flex-end;

      .popup-content-btn {
        padding: 0;
        margin: 0;
        height: fit-content;
      }
    }
  }

  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 10px;

      .ant-popover-inner-content {
        padding: 0 !important;

        .emoji-mart {
          border: none;
        }
      }
    }
  }
}
