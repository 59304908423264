.btn-center {
  text-align: center;
  span {
    text-align: center;
    width: 100%;
  }
}

.btn {
  font-size: 14px;
  border-radius: 0.2rem !important;
  outline: none;
  box-shadow: none;
  border: none;
  text-align: flex-start;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  &-load-more {
    border-radius: 0.15rem !important;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border-radius: 0.15rem !important;
    }
    &:active,
    &:focus {
      border-radius: 0.15rem !important;
    }
  }
  .btn-icon {
    display: flex;
    align-items: center;
  }
  @include xs {
    min-width: fit-content;
  }
  @include sm {
    min-width: fit-content;
  }
}
