@import "./../../../scss/tools/mixins";
@import "./../../../scss/settings/global";
@import "./../../../scss/settings/colors";

.message-post {
  border-radius: 4px;
  border: 1px solid $grey2;
  background: $white-color;
  box-shadow: 0px 6px 80px 0px rgba(45, 45, 46, 0.15);
  .nb-faded-text {
    color: #949599 !important;
  }
  .nb-more-button {
    :hover {
      border: none !important;
    }
  }
  .post-head-info {
    button {
      font-family: inherit !important;
    }
  }
}

.ant-notification {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px !important;
  right: 0px !important;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0px;
  .ant-notification-notice {
    margin: 0px;
    max-width: 952px;
    width: 100%;
    overflow: inherit !important;
    svg {
      background: white;
      padding: 10px;
      border-radius: 50%;
    }
  }
  .ant-notification-notice-message {
    text-align: center;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 32px !important;
    font-weight: 500;
  }
  .ant-notification-notice-description {
    text-align: center;
    margin: 0px !important;
    font-size: 16px !important;
  }
  .ant-notification-notice-icon {
    display: flex;
    position: relative !important;
    justify-content: center;
    margin-top: -70px;
  }
  .ant-notification-notice-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px !important;
  }
}
.nb-post-notification {
  .ant-notification-notice-close {
    right: 16px !important;
  }
}

.ant-modal-content {
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 24px;
      .nb-divider {
        max-width: 241px;
      }
    }
    .ant-modal-confirm-content {
      font-size: 20px;
    }
  }
}
