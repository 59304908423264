@import "./../settings/global";
@import "./../settings/colors";

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", "Helvetica", "Assistant", sans-serif;
  font-size: $base-font-size;
  line-height: $base-line-height;
  background-color: $body-bg-color;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.17rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.83rem;
}
h6 {
  font-size: 0.67rem;
}
p {
  font-size: 1rem;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

::-moz-selection {
  color: $grayishBlack;
}

:focus {
  outline: none;
}
