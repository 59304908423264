.card-header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 0.5rem 0.5rem;
  @extend .apply-bottom-border-grey;

  &-next-btn {
    min-width: 60px;
  }

  .back-icon-container {
    display: flex;
    align-items: center;
    flex: 1;
    max-width: 4.5%;

    .back-icon {
      width: 2rem;
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-left: 2.5rem;
    flex: 2;

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 1rem;
    }

    .title {
      margin: 0;
      font-size: 1.15rem;
    }
  }

  .next-btn-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @include xs {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    .back-icon-container {
      max-width: 8%;
    }

    .title-container {
      margin-left: 1.15rem;

      .icon {
        width: 1.25rem;
        height: 1.25rem;
      }

      .title {
        font-size: 1rem;
      }
    }
  }
}
