.post-parent {
  display: flex;
  align-items: center;
  border: 2px solid $grey2;
  cursor: pointer;
  border-radius: 4px;
  background-color: $white-color;
  box-shadow: 0px 6px 80px 0px rgba(45, 45, 46, 0.15);
  position: relative;
  margin-bottom: 0.75rem;
  padding: 1rem;
  .avatar-container {
    flex: 1;
    max-width: 9%;

    @include sm {
      max-width: 10%;
    }
  }

  .title-container {
    flex: 2;
    max-width: 91%;
    padding: 0.6rem 1.5rem;
    border-radius: 0.5rem;

    @include xs {
      display: flex;
      justify-content: flex-start;
    }

    @include sm {
      width: 95%;
    }

    .title {
      margin: 0;
      padding: 0;
      font-size: 1.1rem;
      color: $grayishBlack;

      @include xs {
        font-size: 0.85rem;
        text-align: center;
      }
    }
  }
}
.custom-input-container {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  .max-char-container {
    padding: 0.5rem 0.5rem;
  }
}
