@import "./../../scss//tools/mixins";

.post-filter-parent {
  display: flex;
  justify-content: space-between;
}

@include xxs {
  .post-filter-parent {
    flex-direction: column;
    .post-filter-parent-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.post-listing-content {
  max-width: 680px;
  margin: auto;
  @include md-max-below {
    max-width: 680px;
    padding: 0px 10px;
  }
}

.activity-parent {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 5px;
}
.profile-img-section {
  width: 100px;
  height: 100px;
}
.acitivity-count-cont {
  display: flex;
  align-items: center;

  .activity-name {
    margin-bottom: 0px;
  }
}
.activity-img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  @include xs {
    width: 80px;
    height: 80px;
  }
  img {
    width: 100%;
  }
}
.neighbour-count {
  p {
    font-size: 16px;
    color: #cb46ff;
  }
}
