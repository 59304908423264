.nb-post-wrapper {
  p {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 0;
  }
  .post-actions-post {
    margin: 0;
    margin-top: 1rem;
  }
}
.post-filter-parent-child {
  span {
    color: black;
  }
}
.post-head-title {
  a {
    font-size: 20px;
  }
}

.nb-listing-layout-main-content {
  .business-layout-content {
    max-width: 1400px;
    margin: 30px 20px;
    .global-shadow-background-page {
      padding: 20px 80px 100px 60px;
      @media screen and (max-width: 768px) {
        padding: 20px 17px 96px 16px;
      }
    }
  }
}
.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}
