.update-post-modal {
  max-width: 952px!important;
  width: 100% !important;
  overflow: hidden;
  margin-top: 5em;

  .update-post-modal-content {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
}
