.custom-input-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .custom-input {
    border: none;
    padding: 1rem 1.75rem;
    font-size: 1rem;
    outline: none;
    box-shadow: none;
    width: 100%;
    flex: 2;

    &:focus,
    &:hover {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .prefix-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 10%;

    @include xs {
      max-width: 20%;
      padding: 1.45rem 0;
    }

    .prefix-icon {
      width: 1rem;
      height: 1rem;
      fill: $primary-color-2;

      path {
        fill: $primary-color-2;
      }
    }

    &-disabled {
      cursor: not-allowed;
    }
  }

  .ant-input-number-input {
    padding-left: 0;
  }
}

.max-char-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 1.75rem;

  .max-char-text {
    padding: 0;
    margin: 0;
    font-size: 0.85rem;
  }
}

.show-emoji-icon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  margin-left: 1rem;
}

.input-outlined {
  padding: 0.8rem 1.25rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  background-color: $white !important;

  .input-label {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
  }

  .text-input {
    outline: none;
    border: none;
    flex: 2;
    box-shadow: none;
  }

  .ant-select,
  .ant-select-selector,
  .ant-input-group-addon {
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: $grayishBlack !important;

    &:focus,
    &:hover,
    &:active {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .input-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
  }

  .custom-select-input {
    .ant-select-selection-item {
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.5rem;
      border: none;
      color: $grayishBlack;

      .anticon-close > svg {
        fill: $primary-color-2;
        margin-left: 0.5rem;

        path {
          fill: $primary-color-2;
        }
      }
    }

    .ant-select-arrow {
      top: 37.5%;
      right: 2%;
    }
  }

  .tag-select {
    .ant-select-selection-item {
      padding: 1rem;
    }
  }
}

.custom-select-dropdown {
  padding: 0;
  border-radius: 0.5rem;

  .ant-select-item-option {
    padding: 0;
    padding-right: 1rem;
    @extend .apply-bottom-border;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      padding: 1.25rem 2rem;

      @include xs {
        padding: 0.75rem 1.25rem;
      }

      @include sm {
        padding: 0.75rem 1.25rem;
      }

      .avatar-container {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;

        .avatar {
          @include xs {
            width: 2.75rem !important;
            height: 2.75rem !important;
          }

          @include sm {
            width: 2.75rem !important;
            height: 2.75rem !important;
          }
        }
      }

      .user-info-container {
        margin-left: 1rem;
        width: 100%;

        .user-info-text {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          color: $grayishBlack;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
        }

        .user-info-name {
          font-size: 1.15rem;
          margin-bottom: 0.15rem;

          @include xs {
            font-size: 1rem;
            margin-bottom: 0;
          }

          @include sm {
            font-size: 1rem;
            margin-bottom: 0;
          }
        }

        .user-info-from {
          @include xs {
            font-size: 0.8rem;
          }

          @include sm {
            font-size: 0.8rem;
          }

          .user-info-address {
            margin-left: 0.25rem;
          }
        }
      }

      .loc-icon-container {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color-2;
        min-width: 3rem;
        min-height: 3rem;

        .loc-icon {
          width: 1rem;
          height: 1rem;
          fill: $primary-color-2;

          path {
            fill: $primary-color-2;
          }
        }
      }
    }

    .ant-select-item-option-state {
      display: flex;
      align-items: center;
    }
  }
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .text-input {
    padding-left: 0;
    width: 100%;
    font-size: 1rem;

    .ant-picker-input {
      input {
        font-size: 1rem;
      }
    }
  }
}

.mh-224 {
  min-height: 224px !important;
}
