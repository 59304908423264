@import "./../../scss/settings/colors";
@import "../../scss/tools/mixins";
@import "../../scss/settings/global";

.compose-alert {
  .ant-modal {
    max-width: 952px;
    width: auto !important;
  }

  .ant-modal-content {
    padding: 33px 10% !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: normal !important;

    .ant-modal-title {
      font-size: 32px !important;
      font-style: normal;
      font-weight: 500 !important;
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        background: white;
        max-width: 110px;
        width: 100%;
        min-height: 110px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-top: -90px;
        padding: 10px;
        svg {
          border-radius: 50%;
        }
      }
    }

    p {
      font-size: 20px !important;
      font-style: normal;
      font-weight: 400 !important;
    }
  }
}
