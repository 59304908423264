@import "../../../scss/tools/mixins";
@import "../../../scss/settings/global";
@import "../../../scss/settings/_colors.scss";

.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  height: 50px;
  z-index: 30;
}

.nav-item {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
}

.nav-item i {
  margin-bottom: 5px;
}

.nav-item:hover {
  color: #ddd;
}

.nav-item-active {
  background: #fff;
  border-radius: $base-border-radius;
  font-weight: bold;

  svg {
    * {
      stroke: $primary-color-2;
    }
  }
}

.community-item {
  display: flex;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  .ant-btn {
    color: $base-font-color !important;
  }
}

/* Media query for smaller screens */
@media (min-width: 768px) {
  .navigation {
    font-size: 0.8rem;
    display: none;
  }
}
