@import "../../../../scss/tools/mixins";
@import "../../../../scss/settings/global";
@import "../../../../scss/settings/_colors.scss";

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.nb-vertical-side-menu {
  min-height: fit-content;
  .side-menu-sub {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .side-menu-lock-icon {
    #Ellipse_79 {
      fill: #aab7c4;
    }
  }
  .menu-item-for-lite-user {
    pointer-events: none;
    border-color: #d9d9d9;
    color: #8189a9;
    a {
      color: #8189a9;
    }
  }
  .nb-vertical-side-menu-item {
    transition: all 0.1s ease-in;
    cursor: pointer;
    .nb-base-text {
      svg {
        width: 35px;
        height: 35px;
        padding: 5px;
      }
    }
    &.side-menu-sub-container {
      margin: 0px;
    }

    &.nb-vertical-side-menu-item-active {
      background: #fff;
      border-radius: $base-border-radius;
      .nb-base-text {
        svg {
          background-color: $primary-color-2;
          border-radius: 5px;
          width: 35px;
          height: 35px;
          padding: 5px;
          * {
            stroke: $white;
          }
        }
      }
      font-weight: bold;
      &.nb-vertical-side-menu-item-primary {
        background: $primary-color;
      }
      &.nb-vertical-side-menu-item-border-simple {
        border-radius: 0;
      }
      a {
        &:active,
        &:hover {
          color: inherit;
        }
        &.nb-vertical-side-menu-text-primary {
          color: $white-color;
          &:hover,
          &:active {
            color: $white-color;
          }
        }
      }
    }
    &.nb-vertical-side-menu-item-show-more {
      flex-direction: row;
      align-items: center;
    }
    // min-height: 46px;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    .side-menu-main {
      display: flex;
      align-items: center;
      flex: 1;
    }
  }

  .logout {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0px;
    width: 90%;
  }
}
.nb-comunities-container {
  flex: 1;
  overflow-y: auto;
  max-height: 50dvh;
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ccc;
    background: #f7fdfb;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f7fdfb;
    border-radius: 0;
    background: #f7fdfb;
  }
}

.mainSider {
  .ant-collapse {
    width: 100%;
    background-color: transparent !important;
    border: none !important;
    .ant-collapse-content {
      border-top: none;
    }
  }
  .ant-collapse-item {
    border-bottom: none;
    width: 100%;
  }
  .ant-collapse-header {
    padding: 0px !important;
    padding-inline-start: 0px !important;
    align-items: center !important;
  }
}
