@import "../../../scss/settings/global";
@import "../../../scss/settings/colors";

.ant-modal {
  max-width: 952px;
  width: 80% !important;
  p {
    margin-bottom: 0px;
  }
}

.nb-report-modal {
  .ant-modal-content {
    padding: 33px 8% !important;
    .ant-modal-body {
      width: 100%;
      font-size: 16px !important;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
    .ant-col {
      width: 100%;
    }

    .ant-form-item-label {
      text-align: start !important;
      label {
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400 !important;
      }
    }
  }

  .ant-select {
    display: flex;
    align-items: center;
    box-shadow: 0px 3.2px 32px 0px rgba(45, 45, 46, 0.08);

    .ant-select-selector {
      border-radius: 4px;
      border: none !important;
    }

    .ant-select-arrow {
      right: 11px;
    }
  }

  .ant-modal-title {
    font-size: 32px !important;
    font-style: normal;
    font-weight: 500 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      background: white;
      max-width: 117px;
      width: 100%;
      min-height: 117px;
      max-height: 117px;
      height: auto;

      border-radius: 50%;
      margin-top: -90px;
      padding: 10px;
      svg {
        border-radius: 50%;
      }
    }
  }
}
