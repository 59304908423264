.mobile-sheet-container {
  .ant-modal-footer {
    position: absolute;
    bottom: 80px;
    width: 90%;
    display: flex;
    justify-content: center;
    padding-top: 10%;
    border-top: 1px solid lightgray;
    .nb-button {
      margin: 0px 5px;
      min-width: 90px;
      border-radius: 4px !important;
      justify-content: center;
    }
  }
}
