.mobile-sheet-container {
  .message-journey-container {
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    .ant-upload-wrapper {
      max-height: 100px;
      overflow: auto;
    }
  }
  .card-footer {
    position: absolute;
    bottom: 80px;
    width: 90%;
    display: flex;
    justify-content: center;
    padding-top: 10%;
    border-top: 1px solid lightgray;
    .ant-btn {
      margin: 0px 5px;
      min-width: 90px;
      border-radius: 4px !important;
      justify-content: center;
    }
  }
}
