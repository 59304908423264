@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "../../scss/settings/variable";
@import "../../scss//settings/colors";

$base-font-size: 14px;
$base-line-height: 1.5715;
$base-border-radius: 0.57rem;
$base-border-radius-sm: calc(0.57rem / 2);

body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 20px;
}
h1 {
  font-size: 3.5rem;
  @media (max-width: 1100px) {
    font-size: 2rem;
  }
}
h2 {
  font-size: 3rem;
  @media (max-width: 1100px) {
    font-size: 1.8rem;
  }
}
h3 {
  font-size: 2.5rem;
  @media (max-width: 1100px) {
    font-size: 1.5rem;
  }
}
h4 {
  font-size: 1.3rem;
  @media (max-width: 1100px) {
    font-size: 1.2rem;
  }
}
h5 {
  font-size: 1.2rem;
  @media (max-width: 1100px) {
    font-size: 1.1rem;
  }
}
h6 {
  font-size: 1.1rem;
  @media (max-width: 1100px) {
    font-size: 1rem;
  }
}
.ant-input-lg {
  padding: 6.5px 20px;
}
.sub-title {
  font-size: 1.8rem;
}
.m0 {
  margin: 0px;
}

.ant-form-item-label {
  > label {
    color: #aab7c4;
    font-size: 0.8rem !important;
    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
      margin-left: 0.2rem;
    }
  }
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.success-pop-width {
  width: 1000px;
}

.gradient-background {
  background: url("../../assets/images/landingPage/banner.webp");

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #120641;
  }
}

.ant-upload-list-item-card-actions-btn {
  .anticon-delete {
    path {
      fill: red;
    }
  }
}

.notification-container {
  .ant-dropdown-menu-item:hover {
    color: white !important;
    background: $grey !important;

    button {
      color: white !important;
    }
  }
  .nb-item-notification {
    box-shadow: 0px 2px 5px 0px #2d2d2e0f;
    border-radius: 10px;
  }
}

.nb-full-screen {
  height: 100%;
}
.nb-default-icon {
  font-size: 1.5rem;
  color: $darkBlue;
  padding: 0px 5px;
}
.global-shadow-background-page {
  background-image: url(../../assets/images/bottomshade.svg),
    url(../../assets/images/topshade.svg);
  background-position:
    left bottom,
    right top;
  background-repeat: no-repeat;
  background-size: 500px;
}

.btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  box-shadow: none;
}

.nb-listing-layout-main-content {
  background-image: url(../../assets/images/bodybg.svg);
  background-position: center;
  background-size: 100%;
  height: 100%;
  @media screen and (max-width: 1200px) {
    padding: 0px 10px;
  }
}
.heading-para {
  p {
    color: black;
  }
}
.ant-avatar-circle {
  border: 2px solid;
  color: $white;
  min-width: 40px;
  min-height: 40px;
}
.post-comment-form {
  .ant-upload {
    &.ant-upload-select {
      display: none;
    }
  }
}

.notification-dropdown {
  .ant-badge-count {
    font-size: 12px !important;
  }
}
.dropdown-wrapper {
  z-index: 30 !important;
}

.ant-result-extra {
  display: flex;
  justify-content: center;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 21;
}

.bottom-sheet-menu {
  .ant-btn {
    color: $base-font-color !important;
  }
}
