@import "./../../../../scss/tools/mixins";

.emoji-mart-trigger-icon {
  height: 20px;
  width: 20px;
  margin-top: 6px;
}

.emoji-mart-wrap {
  .emoji-mart {
    max-width: 272px;
    .emoji-mart-scroll {
      &::-webkit-scrollbar {
        width: 4px;
        position: absolute;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px #ccc;
        background: #f7fdfb;
        position: relative;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f7fdfb;
        border-radius: 0;
        background: #f7fdfb;
      }
    }
    .emoji-mart-bar {
      &:last-child {
        display: none !important;
      }
    }
  }
  @include xs {
    .emoji-mart {
      width: 100% !important;
      max-width: 240px;
    }
    .ant-popover-content {
      // position: relative;
      // top: -30px;
      // margin-left: 10px;

      position: absolute;
      bottom: 40px;
      right: -20vw;
      // right: calc(-20vw - -30px);
    }
    .ant-popover-arrow {
      display: none;
    }
  }
  .emoji-mart-preview {
    display: none;
  }
}
