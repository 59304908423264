.confirm-alert {
  .ant-modal {
    max-width: 952px;
    width: auto !important;
  }

  .ant-modal-content {
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: normal !important;

    .ant-modal-confirm-title {
      font-size: 32px !important;
      font-style: normal;
      font-weight: 500 !important;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        position: absolute;
        top: -50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon {
          background: white;
          max-width: 110px;
          width: 100%;
          min-height: 110px;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 10px;

          svg {
            border-radius: 50%;
          }
        }
      }
    }

    .ant-modal-confirm-content {
      padding-top: 90px;
      padding-bottom: 10px;
      color: #3055a6;
    }

    p {
      font-size: 20px !important;
      font-style: normal;
      font-weight: 400 !important;
    }
  }
}
